<template>
    <div class="main-input datepicker" :class="{error: !inputModel.isValid, opened: isOpen, [inputModel.class]: inputModel.class}">
      <div class="input-body" @click="openList" ref="input">
        <div class="margin-right22">
          <div class="formated-value">{{formatedValue}}</div>
        </div>
      </div>
      <teleport to='body'>
          <ul v-if="isOpen" class="datepicker" v-click-outside="onClickClose" :style="{top: top + 'px', left: left + 'px'}">
              <li>
                  <DatePicker v-if="!inputModel.isMultiselect" ref="calendar" v-model="valueLocal" :attributes='attrs' color="blue" locale="en"/>
                  <DatePicker v-else ref="calendar" v-model="valueLocal" :attributes='attrs' is-range color="blue" locale="en"/>
              </li>
          </ul>
      </teleport>

      <div class="clearable" v-if="!inputModel.readonly && inputModel.clearable" @click.stop="clear" v-html="Cross"></div>
      <div class="error-icon" v-if="!inputModel.isValid" v-html="InfoCircle"></div>
      <div class="calendar-down-icon" v-if="inputModel.isValid" v-html="CalendarIcon"></div>
    </div>   
</template>

<script>
import { inputMixin } from '@/utils/input-mixin'
import { CalendarIcon } from '@/utils/icons'
import vClickOutside from 'click-outside-vue3'
import { DatePicker  } from 'v-calendar';

export default {
  name: 'InputDatePicker',
  mixins: [inputMixin],
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: { DatePicker },

  data() {
    return { 
      CalendarIcon,
      isOpen: false,
      valueLocal: null,
      top: 0,
      left: 0,
      attrs: [
        {
          key: 'today',
          highlight: {
            fillMode: 'outline',
          },
          dates: new Date(),
        },
      ],
    }
  },

  created() {
    if(this.modelValue.value) {
      if(this.inputModel.isMultiselect)
        this.valueLocal = {
          start: new Date(this.modelValue.value[0]),
          end: new Date(this.modelValue.value[1])
        }
      else this.valueLocal = this.modelValue.value 
    }
  },

  computed: {
    formatedValue() {
      let formatDate = (value) => {
        let date = new Date(value)
        let day = date.getDate()
        let month = date.getMonth()+1
        let year = date.getFullYear()

        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month

        return `${year}-${month}-${day}`
      }

      if(!this.modelValue.value) return ''

      if(this.inputModel.isMultiselect)
        return `${formatDate(this.modelValue.value[0])} - ${formatDate(this.modelValue.value[1])}`  
      return formatDate(this.modelValue.value)
    },
  },

  watch: {
      valueLocal() {
        if(this.valueLocal) {
          if(this.inputModel.isMultiselect)
            this.inputModel.value = [this.valueLocal.start.toJSON(), this.valueLocal.end.toJSON()]
          else this.inputModel.value = this.valueLocal  
        }
        this.validate()
      },

      isOpen() {
        if(this.isOpen) {
          this.setPosition()
        }
      },

      'inputModel.required'() {
        this.validate()
      },

      'inputModel.value'() {
        if(this.modelValue.value) {
          if(this.inputModel.isMultiselect)
            this.valueLocal = {
              start: new Date(this.modelValue.value[0]),
              end: new Date(this.modelValue.value[1])
            }
          else this.valueLocal = this.modelValue.value 
        }
      }
  },

  methods: {
    setPosition() {
      this.$nextTick(() => {
        let { left, bottom, height } = this.$refs.input.getBoundingClientRect()
        //this.top = bottom + 4 /*+ window.scrollY*/
        this.left = left /*+ window.scrollX*/

        // Значения магических чисел
        // 270 высота календаря
        // 4 отступ от инпута
        let topDropdown = bottom + 4 
        if(topDropdown + 270 < document.body.clientHeight) this.top = topDropdown
        else {
          this.top = bottom - 4 - height - 270
        }
      })
    },

    clear() {
      this.inputModel.value = null
      this.valueLocal = null
    },

    onClickClose() {
      this.isOpen = false
    },

    openList() {
      this.isOpen = !this.isOpen
    },

    validate() {
        if(this.inputModel.required && !this.inputModel.value) this.inputModel.isValid = false
        else this.inputModel.isValid = true
    }
  },
}
</script>